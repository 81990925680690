import React from 'react'
import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components'

const SearchBoxWrapper = styled.div`
width: 300px;
`

const SearchBoxComponent = (props) => {

    const options = [
        { value: '@all', label: '@all' },
        { value: 'timber', label: 'timber' },
        { value: 'CLT', label: 'CLT' },
        { value: 'brick', label: 'brick' },
        { value: 'steel', label: 'steel' },
        { value: 'concrete', label: 'concrete' },
        { value: 'prefabricated', label: 'prefabricated' },
        { value: 'cantilever', label: 'cantilever' },
        { value: 'residential', label: 'residential' },
        { value: 'alterations and additions', label: 'alterations and additions' }

    ]

    return (
        <SearchBoxWrapper>
            <CreatableSelect id="searchTextBox" options={options} onChange={props.handleChange} formatCreateLabel={(e)=>`Search for ${e}`} />
        </SearchBoxWrapper>
    )
}

export default SearchBoxComponent

import React, { useState } from "react";
import { ReactComponent as AddIcon } from "./icons/add-24px.svg";
import { ReactComponent as ChatIcon } from "./icons/chat-24px.svg";
import { ReactComponent as ExpandIcon } from "./icons/expand_more-24px.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings-24px.svg";
import { ReactComponent as ArrowRightIcon } from "./icons/keyboard_arrow_right-24px.svg";
import { ReactComponent as NotificationsIcon } from "./icons/notifications-24px.svg";
import { ReactComponent as PersonIcon } from "./icons/person-24px.svg";
import { ReactComponent as FireplaceIcon } from "./icons/fireplace-24px.svg";
import { ReactComponent as ArrowBackIcon } from "./icons/arrow_back-24px.svg";
import { CSSTransition } from "react-transition-group";
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import DropdownItem from './DropdownItem'
import SearchBoxComponent from "./SearchBoxComponent";

const Nav = styled.nav`
  height: 60px;
  /* background-color: #242526; */
  padding: 0 1rem;
  /* border-bottom: 1px solid #474a4d; */
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10000;
  /* background: linear-gradient(to right, #642B73 , #C6426E); */
  background: linear-gradient(to right, #283548 , #20252D);
`

const NavbarNavRight = styled.ul`
  /* max-width: 100%; */
  height: 100%;
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`

const NavbarNavLeft = styled.div`
display: flex;
align-items: center;
list-style: none;
  margin: 0;
  padding: 0;
`

const NavItemLeft = styled.div`
padding-left: 10px;
&:hover {
  filter: brightness(1.2);
  cursor: pointer;
}
/* color: white; */
`

const NavItemRight = styled.div`
padding-right: 10px;
color: rgba(255,255,255,0.6);
`

const NavItemRightTour = styled.div`
padding-right: 10px;
color: rgba(255,255,255,0.6);
&:hover {
  filter: brightness(1.2);
  cursor: pointer;
}
`

const NavItemCSS = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.3rem;
  position: relative;
  &:hover{
    filter: brightness(1.2)
  }
  :last-of-type {
  margin-right: 0;
    z-index: 99999;
}
`

const Menu = styled.div`
  padding: 1rem 0;
  width: 100%;
`

// const MenuItem = styled.button`
//   display: flex;
//   align-items: center;
//   background: transparent;
//   border-radius: 8px;
//   transition: all 150ms ease;
//   color: #dadce1;
//   width: 100%;
//   padding: 0.5rem;
//   font-weight: bold;
//   width: 300px;
//   margin: 0 1rem;
//   border: 0;
//   outline: 0;
//   cursor: pointer;
//   &:hover {
//   background: #3a3c3f;
//   z-index: 99999;
// }
// `

const IconButton = styled.button`
  width: calc(50px * 0.65);
  height: calc(50px * 0.65);
  background-color: #3a3c3f;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease;
  border: 0;
  outline: 0;
  cursor: pointer;
  svg {
  fill: #dadce1;
  width: 24px;
  height: 24px;
}
`

// const IconButtonSpan = styled.span`
//   width: calc(50px * 0.65);
//   height: calc(50px * 0.65);
//   background-color: #3a3c3f;
//   border-radius: 50%;
//   padding: 5px;
//   margin: 2px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   transition: all 150ms ease;
//   border: 0;
//   outline: 0;
//   cursor: pointer;
//   svg {
//   fill: #dadce1;
//   width: 24px;
//   height: 24px;
// }
// `

// const IconRight = styled.span`
// margin-left: auto;
// svg {
//   fill: #dadce1;
// }
// `

const DropDown = styled.div`
  position: absolute;
  top: calc(100% - 5px);
  /*change this value to allow other menus to be fixed to right side*/ right: 0;
  background: #242526;
  border: 1px solid #474a4d;
  border-radius: 8px;
  overflow: hidden;
  transition: height 400ms ease;
`

const DropDownItemText = styled.div`
padding-left: 10px;
`


function NavBarComponent(props) {
  // const [open, setOpen] = useState(false);



  return (
    <>
      <Navbar loggedIn={props.loggedIn} selectedProject={props.selectedProject} counter={props.counter} handleChange={props.handleChange} handleClick={props.handleClick} isTourOpen={props.isTourOpen} setIsTourOpen={props.setIsTourOpen}>
        {/* <NavItem icon={<AddIcon />} />
        <NavItem icon={<ChatIcon />} />
        <NavItem icon={<NotificationsIcon />} /> */}
        <NavItem icon={<ExpandIcon />}>
          <DropdownMenu loggedInUsername={props.loggedInUsername} />
        </NavItem>
      </Navbar>
    </>
  );
}

function Navbar(props) {
  const [navbarTileUrl, setNavbarTileUrl] = useState("https://radial-images.s3.amazonaws.com/documents/tiles.jpg")


  const handleTileChange = () => {
    props.handleClick()
    setNavbarTileUrl(navbarTileUrl === "https://radial-images.s3.amazonaws.com/documents/map.jpg" ? "https://radial-images.s3.amazonaws.com/documents/tiles.jpg" : "https://radial-images.s3.amazonaws.com/documents/map.jpg")
  }

  return (
    <Nav>
      <NavbarNavLeft>
        {props.loggedIn ? <NavItemLeft><SearchBoxComponent handleChange={props.handleChange}/></NavItemLeft> : null}
        <NavItemLeft id="cardMapLibraryToggle" onClick={handleTileChange}><img height="35px" width="60px" src={navbarTileUrl} alt=""/></NavItemLeft>
        
        {/* <NavItemLeft><button id="cardMapLibraryToggle" onClick={props.handleClick}>Toggle card/map view</button></NavItemLeft> */}
        <NavItemLeft><div id="counter" style={{color: "white"}}>{props.counter}</div></NavItemLeft>
      </NavbarNavLeft>
      {/* <NavbarNavRight><div>Powered by Radial</div></NavbarNavRight> */}
      <NavbarNavRight>

        <NavItemRightTour><div style={{fontSize: 20, paddingRight: "10px"}} onClick={() => props.setIsTourOpen(true)}>Take a tour</div></NavItemRightTour>
        <NavItemRight><img id="cantileverLogo" onClick={() => window.open("https://cantileverstudio.com.au", '_blank')} height="30px" src="https://radial-images.s3.amazonaws.com/documents/cantilever.png" alt='' /></NavItemRight>
        <NavItemRight><img height="30px" src="https://radial-images.s3.amazonaws.com/documents/icon.png" alt='' /></NavItemRight>
        <NavItemRight id="radialLogo"><a style={{color: "rgba(255,255,255,0.6)"}} href="https://radialsolutions.com.au" target="_blank" rel="noopener noreferrer">Powered by Radial</a></NavItemRight>
        {/* {props.children} */}
      </NavbarNavRight>
    </Nav>
  );
}

function NavItem(props) {
  const [open, setOpen] = useState(false);

  return (
    <NavItemCSS >
      <IconButton onClick={() => setOpen(!open)}>
        {props.icon}
      </IconButton>
      {open && props.children}
    </NavItemCSS>
  );
}

function DropdownMenu(props) {
  console.log(props)
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  // function DropdownItem(props) {
  //   return (
  //     <MenuItem
  //       onClick={(e) => {
  //         props.goToMenu && setActiveMenu(props.goToMenu)
  //         console.log(e.target.children[1])
  //       }}
  //     >
  //       <IconButtonSpan>{props.leftIcon}</IconButtonSpan>
  //       {props.children}
  //       <IconRight>{props.rightIcon}</IconRight>
  //     </MenuItem>
  //   );
  // }

  return (
    <DropDown style={{ height: menuHeight }}>
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={400}
        classNames="menu-primary"
        onEnter={calcHeight}
      >
        <Menu>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon={<PersonIcon />}><DropDownItemText style={{ textTransform: "capitalize" }}>{props.loggedInUsername}</DropDownItemText></DropdownItem>
          <DropdownItem path="/library" setActiveMenu={setActiveMenu} leftIcon={<PersonIcon />}><DropDownItemText>Library</DropDownItemText></DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu}
            leftIcon={<SettingsIcon />}
            rightIcon={<ArrowRightIcon />}
            goToMenu="tools"
          >
            <DropDownItemText>Tools</DropDownItemText>
          </DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu}
            leftIcon={<FireplaceIcon />}
            rightIcon={<ArrowRightIcon />}
            goToMenu="tutorials"
          >
            <DropDownItemText>Tutorials</DropDownItemText>
          </DropdownItem>
          <DropdownItem path='/workmap2' setActiveMenu={setActiveMenu} leftIcon={<PersonIcon />}><DropDownItemText>Log out</DropDownItemText></DropdownItem>
        </Menu>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "tools"}
        unmountOnExit
        timeout={400}
        classNames="menu-secondary"
        onEnter={calcHeight}
      >
        <Menu>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon={<ArrowBackIcon />} goToMenu="main">
            <DropDownItemText><big><big>Tools</big></big></DropDownItemText>
          </DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} path="/missingcoords" leftIcon="📑"><DropDownItemText>Missing coordinates</DropDownItemText></DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} path="/goradialapplogins" leftIcon="🔒"><DropDownItemText>go.radialapp.com Login List</DropDownItemText></DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} path="/goradialappusers" leftIcon="🔒"><DropDownItemText>go.radialapp.com Users List</DropDownItemText></DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} path="/goradialappsearches" leftIcon="🔒"><DropDownItemText>go.radialapp.com Searches List</DropDownItemText></DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} path="/mapboxcluster" leftIcon="📳"><DropDownItemText>MapBox Cluster</DropDownItemText></DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon="🌍"><DropDownItemText>AWS S3 Document Upload</DropDownItemText></DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon="📳"><DropDownItemText>Elastic Search</DropDownItemText></DropdownItem>
        </Menu>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "tutorials"}
        unmountOnExit
        timeout={400}
        classNames="menu-secondary"
        onEnter={calcHeight}
      >
        <Menu>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon={<ArrowBackIcon />} goToMenu="main">
            Back
          </DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon="🎁">Flutter firebase</DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon="🎑">CSS Grid</DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon="🎪">CSS FlexBox</DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon="🛒">VSCode Tips</DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon="🎃">VueJs Beginner's guide</DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon="🧣">CSS Variables</DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon="🔊">Git explained</DropdownItem>
          <DropdownItem setActiveMenu={setActiveMenu} leftIcon="⛳">Beyond console.log</DropdownItem>
        </Menu>
      </CSSTransition>
    </DropDown>
  );
}

export default NavBarComponent;

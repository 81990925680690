import React from 'react'
import styled, { keyframes } from "styled-components"

const Container = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, 250px);
grid-auto-rows: minmax(200px,3fr);
gap: 20px;
justify-content: center;
padding-top: 30px;
padding-bottom: 20px;
text-align: center;
  `

const slidein = keyframes`
from {
  opacity: 0
}

to {
  opacity: 1
}
`

const Card = styled.div`
background-color: white;
padding-bottom: 5px;
transition: filter 200ms;
box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.1);
border-radius: 3px;
animation-duration: 1s;
animation-name: ${slidein};
&:hover {
  filter: brightness(1.2);
  cursor: pointer;
}
`

const ImageGrid = (props) => {
  return (
    <Container>
      {props.data.map((project) => {
        const matchType = project.colour === "white" ? "info" : "notes"
        // return <Card key={project.value}><img src={project.photo_url} alt="central images" width="250" height="250" /><div>{`${project.label}`}</div></Card>
        return <Card onClick={() => console.log(project)} key={project.value}><img src={project.photo_url} alt="central images" width="250" height="200" /><div>{project.architect}</div></Card>
      })}
    </Container>
  )
}

export default ImageGrid

import React, { useState, useEffect } from "react";
import { ReactTabulator } from "react-tabulator";
import {withRouter} from 'react-router-dom'
import TabulatorTable from "./TabulatorTable"
import tasksColumns from "./utils/tabulator-columns"
import styled from 'styled-components'
import { awsmobile } from './utils/aws-exports';
import Amplify, { Auth } from 'aws-amplify';
Amplify.configure(awsmobile)

const TabulatorWrapper = styled.div`
width: 90%;
 margin: 0 auto;
 padding-top: 8vh;
`

const Workmap = (props) => {

    console.log(props)

    const [tasksData, setTasksData] = useState([]);
    const [tasksGroup, settasksGroup] = useState('hidden_discipline');

    const tasksRef = React.useRef(null);

    async function callBackendAPIWithKey(url, authToken) {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
                Authorization: authToken,
            },
        });
        try {
            const body = await response.json();
            return body;
        }
        catch {
            return 0
        }

    }

    useEffect(() => {
        callBackendAPIWithKey("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/gettasksnocrids?searchTerm=" + props.match.params.project_number, "testtoken").then(
            (res) => {
                setTasksData(res)
                console.log(res);
            }
        );
    }, [props.match.params.project_number])

    useEffect(() => {
        try{
            tasksRef.current.table.setGroupBy(tasksGroup);
        }
        catch{
        }
        
    }, [tasksGroup])

    const options = {
        placeholder: "loading..."
    }

    const handleCellEdit = (cell) =>{
        // if(cell._cell.column.field === "Task_notes"){
        //     Auth.currentSession().then(sessionData => {
        //         callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/update_tasks?column=Task_notes&value=${cell._cell.value}&projectNumber=${props.match.params.project_number}&taskID=${cell._cell.row.data.task.split(" - ")[0]}`, sessionData.idToken.jwtToken).then(
        //             (res) => {
        //                 console.log("success")
        //                 // res = res.Users.map(user => ({ "username": user.Username }))
        //                 // // document.activeElement.blur()
        //                 // //console.log(res)
        //                 // setData(res)
        //             }
        //         ).catch((e) => {
        //             console.log(e)
        //         })
        //     })
        //     // console.log(cell._cell.row.data.task.split(" - ")[0])
        //     // console.log(cell._cell.row.data.Task_id_letter)
        //     // console.log(cell._cell.column.field)
        //     // console.log(props.match.params.project_number)
        // }
    }

    return (
        <div>
            <TabulatorWrapper>
                <h2>{props.selectedProject.label}</h2>
                <ReactTabulator ref={tasksRef} columns={tasksColumns} data={tasksData} options={options} cellEdited={handleCellEdit} />
            </TabulatorWrapper>
        </div>
    )
}
export default withRouter(Workmap)

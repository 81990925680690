import React, { useState, useEffect } from "react";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import Login from './Login';
import GlobalStyle from './GlobalStyle'
import WorkMAP from "./Workmap"

//import Loadable from 'react-loadable'
import Library from './Library'
import { SpinnerRoundOutlined } from 'spinners-react';

import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from './utils/aws-exports';
import WorkMAP2 from './Workmap'
import NavBarComponent from "./NavBarComponent";
import MapboxMapCluster from "./MapBoxMapCluster"
import Tour from "reactour";
Amplify.configure(awsmobile)

// const LoadableTabulatorTable = Loadable({loader: () => import('./TabulatorTable'),   loading() {
//   return <div>Loading...</div>
// }});
// const LoadableProjectsMapCircle = Loadable({loader: () => import('./ProjectsMapCircle'),  loading() {
//   return <div>Loading...</div>
// }});
// const LoadableImageGrid = Loadable({loader: () => import('./ImageGrid'),  loading() {
//   return <div>Loading...</div>
// }});


function App() {

  let history = useHistory();

  const [data, setData] = useState([]);
  const [pageMode, setPageMode] = useState("map")
  const [searchTerm, setSearchTerm] = useState("frame")
  const [searchTermLength, setSearchTermLength] = useState(0)
  const [brightness, setBrightness] = useState(1)
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedInUsername, setLoggedInUsername] = useState(null);
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [projectNumber, setProjectNumber] = useState('03050');
  const [selectedProject, setSelectedProject] = useState(null);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [client_name, setClient_name] = useState("")

  async function callBackendAPIWithKey(url, authToken) {
    // setBrightness(0.4)
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "qD3XZnW5TSJ4EZ9lBJvP2B57GI6GUD8kU5AsfKb0",
        Authorization: authToken,
      },
    });
    try {
      const body = await response.json();
      // setBrightness(1)
      return body;
    }
    catch {
      return 0
    }
  }

  useEffect(() => {
    console.log("updated state")
    setData([])
    let searchTermCurrentLength = searchTermLength
    if (searchTermCurrentLength > 1) {
      Auth.currentSession().then(sessionData => {
        setBrightness(0.6)
        setIsLoading(true)
        callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getprojectsauthkey?searchTerm=${searchTerm}`, sessionData.idToken.jwtToken).then(
          (res) => {
            console.log(document.getElementById("searchTextBox").children[1].children[0].children[0].innerText)
            if (document.getElementById("searchTextBox").children[1].children[0].children[0].innerText === searchTerm) {
              setCounter(res.length)
              // document.activeElement.blur()
              console.log(res)
              setData(res)
              setBrightness(1)
              setIsLoading(false)
              callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/addradialtestuserssearch?username=${loggedInUsername}&searchtext=${searchTerm}`, sessionData.idToken.jwtToken).then(
                (res) => {
      
                }
              )
            }

          }
        ).catch(() => {
          setBrightness(1)
          setIsLoading(false)
        })
      })
    }


  }, [searchTerm, searchTermLength])

  const handleChange = (e) => {
    console.log(e.value)
    setSearchTermLength(e.value.length)
    setSearchTerm(e.value)
  }

  const setDataFromExternal = (client_id) => {
    setBrightness(0.6)
    setIsLoading(true)
    callBackendAPIWithKey(`https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getexternalclientprojects?client_id=${client_id}`, "no_token").then(
      (res) => {
        if(res.length===0){
          console.log("res length is 0")
        }
          setCounter(res.length)
          // document.activeElement.blur()
          console.log(res)
          setData(res)
          setClient_name(res[0].architect)
          setBrightness(1)
          setIsLoading(false)
          setIsTourOpen(true)

      }
    ).catch(() => {
      window.location.href = "https://go.radialapp.com/login"
      setBrightness(1)
      setIsLoading(false)
    })

  }

  const handleClick = () => {
    setPageMode((pageMode) => pageMode === "card" ? "map" : "card")
  }

  const steps = [
    {
      content: <div style={{textAlign: "center"}}><img  height="80px" src="https://radial-images.s3.amazonaws.com/documents/icon.png" alt='' /><h3>Welcome to radial, {client_name}.</h3><p>On the map you will find all your projects with Cantilever.</p></div>,
    },
    // {
    //   selector: '#searchTextBox',
    //   content: `Type a search term in the box - try "timber" and hit enter. You will then see all the projects related to this term`,
    //   // action: node => {
    //   //   node.focus();
    //   // }
    // },
    {
      selector: '#cardMapLibraryToggle',
      content: 'Use this button to toggle between card and map view for your projects.',
    },
    {
      selector: '#counter',
      content: 'This shows the number of projects you have worked on with Cantilever.',
    },
    {
      selector: '#cantileverLogo',
      content: 'You are searching the database of Cantilever',
    },
    {
      selector: '#radialLogo',
      content: 'Find out more here.',
    },
  ];

  return (
    <Router>
      {loggedIn ? <NavBarComponent loggedIn={loggedIn} selectedProject={selectedProject} handleChange={handleChange} handleClick={handleClick} counter={counter} loggedInUsername={loggedInUsername} isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen}/> : null}
      {loggedIn ? (
        <Switch>
            <Route
              exact
              path="/library"
              render={(props) => (
                <Library counter={counter} searchTerm={searchTerm} data={data} pageMode={pageMode} handleChange={handleChange} handleClick={handleClick} setPageMode={setPageMode} selectedProject={selectedProject} setSelectedProject={setSelectedProject} />
              )}
            />
          <Route
            exact
            path="/workmap/:project_number"
            render={(props) => (
              <WorkMAP projectNumber={projectNumber} selectedProject={selectedProject} />
            )}
          />
          <Route
            exact
            path="/mapboxcluster"
            render={(props) => (
              <MapboxMapCluster data={data} />
            )}
          />
          <Redirect from="login" to="/library"></Redirect>
          <Redirect from="*" to="/library"></Redirect>
        </Switch>
        ) : (
          <Switch>
        <Route
          exact
          path="/login"
          render={(props) => (
            <Login setLoggedIn={setLoggedIn} setLoggedInUsername={setLoggedInUsername} setIsTourOpen={setIsTourOpen} />
          )}
        />
            <Route
              exact
              path="/external/:client_id"
              render={(props) => (
                <><NavBarComponent loggedIn={loggedIn} selectedProject={selectedProject} handleChange={handleChange} handleClick={handleClick} counter={counter} loggedInUsername={loggedInUsername} isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen}/>
                <Library setDataFromExternal={setDataFromExternal} counter={counter} searchTerm={searchTerm} data={data} pageMode={pageMode} handleChange={handleChange} handleClick={handleClick} setPageMode={setPageMode} selectedProject={selectedProject} setSelectedProject={setSelectedProject} />
                </>
              )}
            />
        <Redirect from="*" to="/login"></Redirect>
        </Switch>
        )
      }
      <GlobalStyle />
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        disableFocusLock={true}
      />
      {/* </div> */}
      <div style={{ position: "absolute", top: "50vh", left: "50%", filter: "brightness(2)" }}><SpinnerRoundOutlined enabled={isLoading} size={61} thickness={45} speed={168} color="#823272" /></div>
    </ Router>
  );
}

export default App;
import React, { useState, useEffect } from "react";
import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from './utils/aws-exports';
import styled from 'styled-components'
import { SpinnerRoundOutlined } from 'spinners-react';

Amplify.configure(awsmobile)

// Auth.signUp({
// 	username: 'radialtestuser',
// 	password: 'RadialT3$t*2021',
// 	attributes: {
// 		email: 'charles.harris.de@gmail.com',
// 	},
// });

// Auth.signIn('radialtestuser', 'RadialT3$t*2021')
// .then(success => console.log('successful sign in'))
// .catch(err => console.log(err));

const LoginForm = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`
const LoginFormInput = styled.input`
  margin-top: 10px;
  width: 300px;
  font-size: 20px;
`

const Login = (props) => {
  console.log(props)

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [failedLogin, setFailedLogin] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    // Auth.signIn('radialtestuser', 'RadialT3$t*2021')
    Auth.signIn(username, password)
      .then(success => {
        // setLoading(true)
        console.log(success)
        setFailedLogin(false)
        props.setLoggedIn(true)
        props.setLoggedInUsername(username)
        setLoading(true)
      })
      .catch(err => {
        console.log(err)
        setFailedLogin(true)
        setLoading(false)
      });
      setLoading(true)
       
    console.log(Auth.currentSession())
  }

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  return (
    <LoginForm>
      <img src="https://radialsolutions.com.au/img/logo.jpg" alt="" width="300px" />
      <LoginFormInput onFocus={() => setFailedLogin(false)} onChange={handleUsernameChange} type="username" placeholder="Username" />
      <LoginFormInput onChange={handlePasswordChange} onKeyDown={(e) => { if (e.key === 'Enter') { return handleLogin() } }} type="password" placeholder="Password" />
      <button style={{ marginTop: "10px", width: "300px", fontSize: "20px" }} onClick={handleLogin}>Login</button>
      <p style={{ width: "300px", color: "red", fontSize: "20px", display: failedLogin === true ? "block" : "none" }}>Incorrect username or password</p>
      {/* <button onClick={getSession}>Get user session</button> */}
      {loading ? <div style={{ paddingTop: "10px" }}><SpinnerRoundOutlined enabled={true} size={61} thickness={45} speed={168} color="#823272" /></div> : <div></div>}
    </LoginForm>
  )
}

export default Login


import React, {useEffect} from 'react'
import ImageGrid from './ImageGrid'
import styled from 'styled-components'
import MapBoxMapCluster from './MapBoxMapCluster'
import {withRouter} from 'react-router-dom'
import MapBoxMapClusterExternal from './MapBoxMapClusterExternal'
import ImageGridExternal from './ImageGridExternal'

const Main = styled.div`
height: 95vh;
padding-top: 5vh;
`

const Library = (props) => {

    useEffect(() => {
        if(props.location.pathname !== "/library"){
            props.setDataFromExternal(props.match.params.client_id)
            console.log(props.match.params.client_id)
        }
    }, [])

    console.log(props)

    return (
        <>
{ props.location.pathname === "/library" ? <Main>{props.pageMode === "card" ? <ImageGrid data={props.data} searchTerm={props.searchTerm} /> : <MapBoxMapCluster data={props.data} selectedProject={props.selectedProject} setSelectedProject={props.setSelectedProject} />}</Main> : <Main>{props.pageMode === "card" ? <ImageGridExternal data={props.data} searchTerm={props.searchTerm} /> : <MapBoxMapClusterExternal data={props.data} selectedProject={props.selectedProject} setSelectedProject={props.setSelectedProject} />}</Main>}
</>
    )
}

export default withRouter(Library)

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`


* {
  box-sizing: border-box;
}

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: linear-gradient(to right, #8e9eab, #eef2f3); */
  }

  .menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0);
  transition: all 400ms ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 400ms ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}

.menu-secondary-enter-active {
  transform: translateX(0);
  transition: all 400ms ease;
}

.menu-secondary-exit {
  transition: all 400ms ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all 400ms ease;
}

.cluster-marker {
  color: black;
  background: #d4af37 ;
  font-weight: bold;
  /* border-radius: 50%; */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  /* background: none; */
  border: none;
  /* background: #dce1f8; */
  background: #d4af37 ;
  opacity: 0.6;
  /* border-radius: 50%; */
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker img {
  width: 25px;
}

`



export default GlobalStyle